import Card from '../card'
import React from 'react'

import './style.scss'
import Hr from '../hr'
import logoFlymehome from '../../images/logo-flymehome-black.svg'
import logoChuv from '../../images/logo-chuv.svg'
import logoFly7 from '../../images/logo-fly7.svg'
import badgeLinkedin from '../../images/badge-linkedin.svg'

const Footer = () => (
  <footer className="site-footer" id="soutenir">
    <div className="container">
      <div className="row">
        <div className="col-lg-5">
          <h2 className="mt-5 mb-3 mb-lg-0 text-center text-lg-left">
            Soutenir <br />
            Fly Me Home
          </h2>
          <div className="text-center mb-5 d-md-none" aria-hidden="true">
            <h4>Soutenez-nous avec un don</h4>
            <p>
              CCP 14-188922-0<br />
              IBAN CH11 0900 0000 1418 8922 0
            </p>
            <p>
              Fly Me Home<br />
              1000 Lausanne
            </p>
          </div>
        </div>
        <div className="col-md-8 offset-md-4 offset-lg-0 col-lg-7 shifted-card float-right">
          <Card className="card-yellow">
            <h2 className="card-title font-weight-normal mt-1">4 façons<br /> de nous aider</h2>
            <div className="mb-3">
              <h4>Faites un don&nbsp;!</h4>
              Chaque contribution nous aide à payer les équipements et les heures de vol des avions.
            </div>
            <div className="mb-3">
              <h4>Vous possédez un avion&nbsp;?</h4>
              Vous pouvez le mettre à disposition de l'association pour un nombre d'heures que vous déterminez.
            </div>
            <div className="mb-2">
              <h4>Abonnez-vous à notre newsletter</h4>
              On vous explique nos actions en détails.
            </div>
             
            <script type="text/javascript" src="https://js.createsend1.com/javascript/copypastesubscribeformlogic.js"></script>
            <form id="subForm" className="mb-4 js-cm-form" action="https://www.createsend.com/t/subscribeerror?description=" method="post" data-id="A61C50BEC994754B1D79C5819EC1255CA2390AEA89C97326CE458986F0589990B297DCBEB2B110B89DE0A712F9E668298382A5CDF7A1554DAD514D878013609A">
              <div className="input-group mb-3">
                <input 
                  id="fieldEmail" 
                  name="cm-yhduiri-yhduiri" 
                  type="email"
                  className="form-control form-control-sm js-cm-email-input"
                  placeholder="Votre e-mail"
                  aria-label="Votre e-mail"
                  required/>
                <div className="input-group-append">
                  <button 
                    className="btn btn-sm btn-outline-secondary js-cm-submit-button"
                    type="submit">
                      Je m'inscris
                    </button>
                </div>
              </div>
            </form>

            <div>
              <h4>Passez le mot</h4>
              Pour faire grandir notre réseau et sensibiliser les gens au sujet de la fin de vie.
            </div>
          </Card>
        </div>
      </div>
    </div>
    <div className="bg-beige pushed-footer pb-5">
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-lg-5 text-center text-md-left">
            <div className="mt-3 mb-5 mb-lg-5 d-none d-md-block">
              <h2 className="h2">
                Dons
              </h2>
              <div className="mb-3">
                <h4>Soutenez-nous avec un don</h4>
                <p>
                  <strong>CCP</strong> 14-188922-0<br />
                  <strong>IBAN</strong> CH11 0900 0000 1418 8922 0
                </p>
                <p>
                  Fly Me Home<br />
                  1018 Lausanne
                </p>
              </div>
            </div>
            <div className="my-5 mb-lg-0">
              <h3><img className="img-fluid mt-3" src={logoFlymehome} alt="Fly me home" /></h3>
              <p>
                <a className="mb-1 d-inline-block" href="tel:+41 21 314 04 44">+41 21 314 04 44</a><br />
                <a className="mb-1 d-inline-block" href="mailto:info@flymehome.ch">info@flymehome.ch</a>
              </p>
            </div>
          </div>
        </div>
        <div className="row mt-md-5 mt-lg-5 py-lg-4">
          <div className="offset-md-0 col-md-6 text-center text-md-left mb-4 mb-md-0">
            <img className="img-fluid mb-3 logo-chuv" src={logoChuv} alt="Chuv" />
            <p>
              Fly Me Home mobilise le savoir-faire des équipes du CHUV de Lausanne pour l'aspect médical du rapatriement
            </p>
          </div>
          <div className="offset-md-0 col-md-6 text-center text-md-left mb-4 mb-md-0">
            <img className="img-fluid mb-3 logo-fly7" src={logoFly7} alt="Fly7" />
            <p>
              La companie Fly7 et sa flotte privée assurent le transport médicalisé des personnes qui vous sont chères
            </p>
          </div>
        </div>
        <Hr className="mt-md-4 mt-lg-4 pb-5" />
        <div className="text-center">
          <small>Réalisation par <a target="_blank" rel="noopener noreferrer" href="https://www.antistatique.net">Antistatique</a></small>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer


    