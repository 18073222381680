import PropTypes from 'prop-types'
import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import logo from '../images/logo-flymehome.svg'

const Header = ({ siteTitle }) => (
  <>
  <div className="d-block mt-lg-5 pt-4"></div>
  <header className="bg-white sticky-top">
    <div
      className="container py-3 d-lg-flex justify-content-between text-center text-lg-left"
    >
      <h1 style={{ margin: 0 }}>
       <img className="img-fluid mb-4 mb-lg-0" src={logo} alt={siteTitle} />
      </h1>
      <AnchorLink offset={150} className="btn btn-secondary mt-auto" href='#soutenir'>Soutenez-nous</AnchorLink>
    </div>
  </header>
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
