import React from 'react'
import './style.scss'

const Card = ({ children, title, className }) => (
  <div className={`card ${className || ''}`}>
    {children}
  </div>
)

export default Card
